import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1200.000000 1200.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)">
					<path d="M0 6000 l0 -6000 6000 0 6000 0 0 6000 0 6000 -6000 0 -6000 0 0
-6000z m3330 2087 c363 -72 707 -240 990 -483 103 -89 266 -261 291 -307 11
-19 1 -30 -75 -84 l-38 -27 -46 54 c-290 342 -638 561 -1052 664 -110 27 -309
56 -386 56 l-44 0 0 -449 0 -449 78 -7 c116 -10 237 -33 335 -62 l87 -26 0
-224 c0 -122 -4 -223 -9 -223 -4 0 -41 15 -82 34 -137 63 -313 96 -514 96
-138 0 -227 -19 -334 -70 -109 -52 -228 -168 -284 -277 -97 -186 -114 -467
-41 -685 62 -189 216 -334 404 -383 134 -35 353 -28 440 13 l35 17 3 213 2
212 -220 0 -220 0 0 195 0 195 465 0 465 0 0 -545 0 -544 -42 -25 c-105 -61
-307 -123 -480 -148 l-88 -12 0 -383 0 -383 44 0 c75 0 275 29 386 56 370 91
713 294 972 574 51 56 86 86 94 82 33 -19 104 -86 104 -98 0 -24 -250 -266
-363 -350 -262 -196 -576 -334 -900 -395 -161 -30 -498 -38 -657 -15 -697 101
-1282 513 -1605 1130 -95 181 -169 404 -212 636 -26 138 -25 544 1 685 67 366
194 657 414 949 83 109 239 270 347 358 234 188 524 332 821 407 210 53 284
61 549 56 204 -3 259 -7 365 -28z m683 -976 c50 -26 52 -28 38 -50 -8 -12 -12
-12 -25 -2 -9 8 -16 9 -16 3 0 -14 27 -23 48 -15 15 6 15 5 4 -10 -15 -18 -20
-174 -8 -269 7 -56 7 -57 -23 -73 -17 -8 -31 -12 -31 -8 0 5 -4 2 -8 -5 -5 -7
-33 -17 -63 -24 -105 -21 -230 28 -287 115 -51 77 -40 218 23 283 60 62 120
84 225 81 56 -2 87 -9 123 -26z m82 -61 c10 -11 16 -20 13 -20 -3 0 -13 9 -23
20 -10 11 -16 20 -13 20 3 0 13 -9 23 -20z m23 -270 c-7 -10 -14 -17 -17 -15
-4 4 18 35 25 35 2 0 -1 -9 -8 -20z m950 -91 c26 -7 53 -16 60 -22 9 -7 12
-53 12 -168 l0 -159 190 0 190 0 0 -160 0 -160 110 0 110 0 0 -91 0 -91 41 39
c53 51 174 116 265 141 315 87 665 -34 797 -276 52 -95 72 -278 46 -404 -34
-159 -136 -282 -299 -361 -245 -118 -527 -101 -748 47 -37 24 -79 57 -94 73
-47 49 -95 141 -114 216 -27 109 -22 283 12 370 5 15 -2 17 -60 17 l-66 0 0
-380 0 -380 -190 0 -190 0 0 380 0 380 -130 0 -130 0 0 -380 0 -380 -190 0
-190 0 0 380 0 380 -65 0 -65 0 0 160 0 160 63 0 64 0 6 153 c4 93 12 171 22
202 19 62 70 140 127 195 102 98 286 150 416 119z m4552 -74 c52 -27 70 -45
99 -102 40 -79 18 -175 -54 -242 -88 -81 -175 -79 -275 7 -110 95 -98 239 29
326 71 50 118 52 201 11z m-5570 -936 c0 -396 1 -723 4 -727 2 -4 2 -22 0 -39
-3 -26 0 -33 14 -35 9 0 -3 -4 -28 -8 l-45 -7 60 -3 60 -3 -65 -7 c-36 -4
-143 -8 -237 -9 l-173 -1 0 780 0 780 205 0 205 0 0 -721z m3680 360 c126 -20
197 -67 247 -163 56 -109 56 -111 60 -538 l4 -398 -190 0 -191 0 0 333 c0 330
0 332 -24 384 -37 78 -91 103 -190 89 -54 -8 -98 -42 -129 -100 -21 -40 -22
-53 -25 -373 l-3 -333 -190 0 -189 0 0 540 0 540 190 0 190 0 0 -71 0 -70 48
49 c99 102 227 138 392 111z m1294 -20 c78 -24 124 -63 163 -136 60 -111 63
-142 63 -562 l0 -381 -185 0 -185 0 0 328 c-1 315 -1 329 -23 381 -28 69 -60
93 -132 99 -100 8 -170 -35 -205 -128 -10 -26 -15 -121 -18 -357 l-4 -323
-184 0 -184 0 0 540 0 540 185 0 185 0 0 -65 c0 -36 3 -65 6 -65 3 0 31 23 61
50 61 56 100 76 183 96 69 17 191 9 274 -17z m1406 -5 c52 -22 136 -80 144
-100 3 -8 11 -14 19 -14 9 0 13 15 12 53 l0 52 60 3 c57 3 59 2 40 -13 -11 -9
-14 -14 -7 -10 9 5 12 -24 12 -126 0 -119 -2 -131 -15 -113 -14 19 -14 19 -15
-2 0 -12 7 -27 15 -34 22 -18 20 -90 -2 -91 -15 0 -15 -2 2 -8 19 -8 20 -3 25
153 5 132 6 112 6 -109 0 -162 -2 -232 -6 -175 -9 134 -13 77 -11 -148 2 -159
-1 -198 -13 -217 -13 -20 -13 -25 -1 -35 10 -8 16 -33 17 -69 l2 -56 6 70 6
70 2 -67 c1 -37 -1 -69 -5 -71 -5 -3 -33 -6 -63 -8 l-55 -4 -2 50 c-1 28 -2
57 -2 65 -1 9 -18 -4 -41 -31 -76 -89 -249 -143 -382 -119 -121 22 -195 63
-290 163 -68 71 -122 169 -144 263 -23 101 -15 257 19 345 66 173 196 299 356
345 66 19 252 12 311 -12z m-712 -537 l2 -537 -187 2 -188 3 -3 525 c-1 289 0
531 3 538 3 10 47 12 187 10 l183 -3 3 -538z m1009 -304 c-2 -38 -3 -7 -3 67
0 74 1 105 3 68 2 -38 2 -98 0 -135z"/>
					<path d="M2750 7953 c-745 -74 -1379 -552 -1648 -1245 -113 -289 -156 -634
-117 -946 113 -908 814 -1600 1735 -1712 46 -5 88 -10 92 -10 5 0 8 171 8 380
l0 380 -75 0 c-41 0 -124 7 -185 15 -434 61 -732 298 -858 685 -55 167 -72
450 -38 636 72 390 316 692 676 836 101 41 264 77 383 84 l97 7 0 449 c0 421
-1 448 -17 446 -10 0 -34 -3 -53 -5z"/>
					<path d="M4020 7080 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
					<path d="M4025 6710 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
					<path d="M4932 6344 c-39 -35 -52 -94 -52 -230 l0 -94 130 0 130 0 0 158 0
159 -27 13 c-16 7 -55 15 -89 17 -57 5 -63 3 -92 -23z"/>
					<path d="M6190 5711 c-100 -32 -152 -88 -172 -182 -13 -66 2 -151 35 -201 92
-135 344 -127 419 15 19 35 23 58 23 133 0 83 -2 94 -30 139 -39 64 -104 98
-189 102 -34 1 -72 -1 -86 -6z"/>
					<path d="M10264 5707 c-50 -20 -91 -55 -121 -105 -24 -41 -28 -57 -28 -127 0
-69 4 -86 27 -126 50 -85 115 -119 226 -119 166 1 251 94 240 264 -7 108 -58
188 -137 215 -43 14 -167 13 -207 -2z"/>
					<path d="M10605 5590 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
					<path d="M10681 5588 c0 -7 4 -22 9 -33 7 -16 8 -14 7 12 -1 18 -5 33 -9 33
-5 0 -8 -6 -7 -12z"/>
					<path d="M10622 5545 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
					<path d="M10622 5420 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
					<path d="M10606 5364 c-3 -8 1 -22 9 -30 8 -9 15 -11 15 -5 0 5 -4 12 -10 16
-5 3 -7 12 -3 20 3 8 3 15 0 15 -3 0 -8 -7 -11 -16z"/>
					<path d="M10680 5295 c1 -26 2 -28 11 -12 8 14 8 22 0 30 -8 8 -11 3 -11 -18z" />
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
